import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"

const NotFoundPage = () => (
  <Layout
    setCanonical={false}>
    <h2>NOT FOUND</h2>
    <h3>Oops, something's wrong</h3>
    <p>
      You just hit a location  doesn't exist.
    </p>
    <p>
      Let's help you find what you were looking for. Head on back to the <Link to="/">home page</Link>.
    </p>
  </Layout>
)

export default NotFoundPage